import React from 'react'
import './FileSelect.scss'
function FileSelect({wrapperClasses,fileOnChange}) {
  const [fileName, setFileName] = React.useState('No file selected')
  const handleFileSelect = (e) => {
    setFileName(e.target.files[0].name)
   fileOnChange(e)
  }
  return (
    <div className={`FileSelect__wrapper ${wrapperClasses}`}>
      <label htmlFor="fileSelect"><i className='bx bx-upload'></i>
      <span>Upload</span>
      </label>
      <span>{fileName}</span>
        <input type="file" id="fileSelect" onChange={handleFileSelect} className='d-none' />
    </div>
  )
}

export default FileSelect