import React, { useState } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { prabashwaraLogo } from "../../constants/asset";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import Input from "../../components/Input";
import TextArea from "../../components/TextArea";
import Model from "../../components/Model";
import enumValues from "../../constants/enum";
import { toast } from "react-toastify";
import { saveDocument } from "../../hooks/useSave";
function Feedbacks() {
  const initialValues = {
    name: "",
    nameOfSchool: "",
    feedback: "",
  };
  const [modelOpen, setModelOpen] = useState(false);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    nameOfSchool: Yup.string().required("Required"),
    feedback: Yup.string().required("Required"),
  });

  const onSubmit = async(values,formikAction) => {
    try {
        await saveDocument(enumValues.PRABHASHWARA_FEEDBACK_COLLECTION,values);
        toast.success("Feedback submitted successfully");
    }catch (error) {
        toast.error("Something went wrong");
    }

    formikAction.resetForm();
    setModelOpen(false);

  };

  return (
    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
          </div>
          <div className="Registrations__form__wrapper p-5 mt-5 w-100">
            <h2 className="Registrations__form__title text-center">
              Prabhashwara’24 Feedback Forum
            </h2>
            <p className="text-center">Give your feedback about our Inter School Media Competition and Media Day.</p>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              <Form className="w-100 p-0 m-0">
                <div className="inputWrapper">
                  <label>Name *</label>
                  <Input
                    name="name"
                    placeholder="Type here"
                    wrapperClasses="Registration__form__input"
                  />
                </div>
                <div className="inputWrapper">
                  <label>Name of School *</label>
                  <Input
                    name="nameOfSchool"
                    placeholder="Type here"
                    wrapperClasses="Registration__form__input"
                  />
                </div>
                <div className="inputWrapper mb-3">
                  <label>Feedback *</label>
                  <TextArea
                    name="feedback"
                    placeholder="Type here"
                    wrapperClasses="Registration__form__input textarea"
                  />
                </div>
                <button
                    type="button"
                    onClick={() => {
                      setModelOpen(true);
                    }}
                    className="mt-4 w-100 submit"
                  >
                    Submit
                  </button>
                {modelOpen ? (
                  <Model wrapperClassName="registrationPopup">
                    <h2>Are you sure ?</h2>
                    <p className="text-start">
                      The information submitted in the form cannot be changed
                      again. So are you sure about the information you entered ?
                    </p>
                    <div className="action__buttons__container w-100 d-flex">
                      <button
                        className="cancel"
                        onClick={() => {
                          setModelOpen(false);
                        }}
                      >
                        No, I need to check.
                      </button>
                      <button className="submit " type="submit">
                        Yes, I’m sure. Submit
                      </button>
                    </div>
                  </Model>
                ) : null}
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Feedbacks;
