import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import HomePage from "./pages/HomePage";
import SchoolRegistrations from "./pages/Registrations/School";
import Registrations from "./pages/Registrations";
import IndividualRegistrations from "./pages/Registrations/Individual";
import SchoolRegistrationSuccess from "./pages/SchoolRegistrationSuccess";
import IndividualRegistrationSuccess from "./pages/IndividualRegistrationSuccess";

import ProjectSubmission from "./pages/Registrations/ProjectSubmission";
import CommingSoon from "./pages/CommingSoon";
import Rules from "./pages/Rules";
import Preloader from "./components/Preloader/preloader";
import PreloaderPB from "./components/PreloaderPB/preloaderpb";
import TShirts from "./pages/TShirts";
import TshirtFormSubmitted from "./pages/TshirtFormSubmitted";
import Feedbacks from "./pages/Feedbacks";

function Router() {
  return (
    <Routes>
      {/* PUBLIC PAGES */}
      <Route 
        path="/"
        element={
        <>
        <Preloader />
        <CommingSoon />
        </>
        } 
    />
      {/* PUBLIC PAGES */}

      {/* FORM PAGES */}
      <Route
        path="/prabhashwara-merchandise"
        element={
        <>
        <PreloaderPB />
        <TShirts />
        </>
        }
      />
      <Route
        path="/prabhashwara-merchandise-submitted"
        element={
        <>
        <PreloaderPB />
        <TshirtFormSubmitted />
        </>
        }
      />
      <Route
        path="/prabhashwara-feedback"
        element={
        <>
        <PreloaderPB />
        <Feedbacks />
        </>
        }
      />
      {/* <Route
        path="/prabhashwara-registration/rulesandregulations"
        element={
        <>
        <PreloaderPB />
        <Rules />
        </>
        }
      />
      <Route 
        path="/prabhashwara-registration/"
        element={
        <>
        <PreloaderPB />
        <Registrations />
        </>
        } 
      />
      <Route
        path="/prabhashwara-registration/school"
        element={
        <>
        <PreloaderPB />
        <SchoolRegistrations />
        </>
        }
      />
      <Route
        path="/prabhashwara-registration/individual"
        element={
        <>
        <PreloaderPB />
        <IndividualRegistrations />
        </>
        }
      />
      <Route
        path="/prabhashwara-registration/individual/project-submission"
        element={
        <>
        <PreloaderPB />
        <ProjectSubmission />
        </>
        }
      />
      <Route
        path="/prabhashwara-registration/school/:schId/success"
        element={
        <>
        <PreloaderPB />
        <SchoolRegistrationSuccess />
        </>
        }
      />
      <Route
        path="/prabhashwara-registration/individual/success"
        element={
        <>
        <PreloaderPB />
        <IndividualRegistrationSuccess />
        </>
        }
      /> */}
      {/* FORM PAGES */}
    
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default Router;
