const collections = Object.freeze({
  SCHOOL_REGISTRATIONS: "schoolRegistrations",
  INDIVIDUAL_REGISTRATIONS: "individualRegistrations",
  COMPETITORS: "competitors",
  EVENT_COLLECTION: "event",
  TSHIRTS_COLLECTION:"tshirts",
  PRABHASHWARA_MERCHANDISE_COLLECTION:"prabhashwaraMerchandise",
  PRABHASHWARA_FEEDBACK_COLLECTION:"prabhashwaraFeedback",
  PRODUCTS_COLLECTION:"products",
  SCHOOL_REGISTRATION_ID_PREFIX: "SR",
  INDIVIDUAL_REGISTRATION_ID_PREFIX: "C",
  EVENT_SHORT_FILM: "Short film",
  EVENT_GRAPHIC_DESIGNING: "Graphic designing",
  EVENT_PHOTOGRAPHY: "Photography",
  PROJECT_PUBLISHING_URL:
    "/prabhashwara-registration/individual/project-submission",
});
export default collections;
