import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { prabashwaraLogo, tShirt } from "../../constants/asset";
import { Formik, Form } from "formik";
import Input from "../../components/Input/Input";
import Select from "../../components/Select";
import FileSelect from "../../components/FileSelect";
import Model from "../../components/Model";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { firebaseStorage } from "../../config/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import shortid from "shortid";
import cities from "../../assets/json/city.json";
import enumValues from "../../constants/enum";
import { saveDocument } from "../../hooks/useSave";
import { getAllRecords } from "../../hooks/useFetch";
import Product from "../../components/Product/Product";
import { useNavigate } from "react-router-dom";
import "./TShirts.scss";

function TShirts() {
  const courierCharges = 500;
  const [total, setTotal] = useState(0);
  const [modelOpen, setModelOpen] = useState(false);
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [slip, setSlip] = useState(null);
  const [cityData, setCityData] = useState([]);
  const [districtData, setDistrictData] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const navigate = useNavigate();

  const [deliveryData, setDeliveryData] = useState({
    address: "",
    district: "",
    city: "",
    postalCode: "",
  });

  const initialValues = {
    name: "",
    size: "",
    contact: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    size: Yup.string().optional().nullable(),
    contact: Yup.string().required("Required").min(10, "Invalid phone number"),
  });

  useEffect(() => {
    fetchProducts();
    const data = Object.keys(cities);
    setDistrictData(
      data.map((item) => {
        return {
          name: item,
          value: item,
        };
      })
    );
  }, []);

  const handleFormSubmit = async (values) => {
    if (selectedProducts.length === 0) {
      toast.error("Please select products");
      return;
    }
    if (
      selectedProducts.some((item) => item.name === "Tshirt") &&
      values.size === ""
    ) {
      toast.error("Please select size");
      return;
    }

    if (!deliveryMethod) {
      toast.error("Please select delivery method");
      return;
    }
    if (!slip) {
      toast.error("Please upload slip");
      return;
    }

    // FILE UPLOAD TO FIREBASE STORAGE
    const storageRef = ref(
      firebaseStorage,
      `${slip.name}__${shortid.generate()}`
    );
    const uploadTask = uploadBytesResumable(storageRef, slip);
    if (deliveryMethod === "Courier") {
      if (
        deliveryData.address &&
        deliveryData.district &&
        deliveryData.city &&
        deliveryData.postalCode
      ) {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
          },
          (error) => {
            toast.error("file Upload Failed");
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
              saveDocument(enumValues.PRABHASHWARA_MERCHANDISE_COLLECTION, {
                ...values,
                selectedProducts,
                total,
                deliveryMethod,
                deliveryData,
                slip: url,
              })
                .then(() => navigate(`/prabhashwara-merchandise-submitted`))
                .catch((error) => {
                  toast.error("Form submission failed");
                });
            });
          }
        );
      } else {
        toast.error("Please fill all the delivery details");
        return;
      }
    } else {
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
        },
        (error) => {
          toast.error("file Upload Failed");
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            saveDocument(enumValues.PRABHASHWARA_MERCHANDISE_COLLECTION, {
              ...values,
              selectedProducts,
              total,
              deliveryMethod,
              slip: url,
            })
              .then(() => navigate(`/prabhashwara-merchandise-submitted`))
              .catch((error) => {
                toast.error("Form submission failed");
              });
          });
        }
      );
    }
    setModelOpen(false);
  };
  const fetchProducts = async () => {
    const data = await getAllRecords(enumValues.PRODUCTS_COLLECTION);
    setProducts(data);
  };

  const onProductSelect = (name, price, quantity,type) => {
    let temp = selectedProducts;

      const bandIndex = temp.findIndex((item) => item.name === name);
      if (bandIndex >= 0) {
        if (quantity === 0) {
          temp.splice(bandIndex, 1);
          setSelectedProducts([...temp]);
          return;
        }
        temp[bandIndex].quantity = quantity;
        setSelectedProducts([...temp]);
        return;
      }
    setSelectedProducts([...temp, { name, price, quantity,type }]);
  };
  useEffect(() => {
    if (selectedProducts.length > 0) {
      let tempSelectedProducts = [...selectedProducts];
      console.log(selectedProducts);
      let tempTotal = selectedProducts.reduce(
        (a, b) => a + b.price * b.quantity,
        0
      );
    
if (tempSelectedProducts.some((item) => item.type === "cap") && tempSelectedProducts.some((item) => item.type === "wb")) {
  tempTotal-= 99
}
if (selectedProducts.some((item) => item.type === "ts") && selectedProducts.some((item) => item.type === "wb")) {
  tempTotal -= 99
}
if (selectedProducts.some((item) => item.type === "ts") && selectedProducts.some((item) => item.type === "cap")) {
  tempTotal -= 99
}

      setTotal(tempTotal);

    } else {
      setTotal(0);
    }
  }, [selectedProducts]);
  return (
    <div className="container-fluid p-0 m-0 Registrations__wrapper">
      <Header theme="dark" />
      <div className="container">
        <div className="row m-0 p-0">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <img
              src={prabashwaraLogo}
              alt="prabashwaraLogo"
              className="Registrations__logo d-block mx-auto"
            />
            <div className="Registrations__form__wrapper p-5 mt-5 w-100">
              <h2 className="Registrations__form__title text-center">
                Prabhashwara’24 Official Merchandise
              </h2>
              <img
                src="https://dl.dropbox.com/scl/fi/uyfm46zg42ykcuzojw3na/PM-Banner.png?rlkey=48ijv6may8nti854s0zql2yh2&st=dhlgusny&dl=0"
                alt="banner"
                className="banner"
              />
              <h5>Order details</h5>
              <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validationSchema={validationSchema}
              >
                <Form className="w-100 p-0 m-0">
                  <div className="inputWrapper">
                    <label>Name *</label>
                    <Input
                      name="name"
                      placeholder="Type here"
                      wrapperClasses="Registration__form__input"
                    />
                  </div>
                  <div className="row m-0 p-0">
                    <div className="col-lg-7 col-md-7 col-sm-12">
                      <br />
                      <div className="inputWrapper">
                        <label>Select your product(s)
                          <br />
                          You can order products by changing each quantity. Default quantity is ‘0’.
                        </label>
                      </div>
                      <br />
                      <div className="products__list__wrapper">
                        {products.map((product) => (
                          <Product
                            key={product._id}
                            product={product}
                            onProductSelect={(name, price, quantity,type) =>
                              onProductSelect(name, price, quantity,type)
                            }
                          />
                        ))}
                      </div>
                    </div>
                    <div className="col-lg-5 col-md-5 col-sm-12 p-0">
                      <table className="TShirts__table">
                        <thead>
                          <tr>
                            <td colSpan={3}>
                              <b>T shirt size table</b>
                            </td>
                          </tr>
                          <tr>
                            <td>Size</td>
                            <td>Body Width</td>
                            <td>Body length</td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>2XS</td>
                            <td>17”</td>
                            <td>24”</td>
                          </tr>
                          <tr>
                            <td>XS</td>
                            <td>18”</td>
                            <td>25”</td>
                          </tr>
                          <tr>
                            <td>S</td>
                            <td>19”</td>
                            <td>26”</td>
                          </tr>
                          <tr>
                            <td>M</td>
                            <td>20”</td>
                            <td>27”</td>
                          </tr>
                          <tr>
                            <td>L</td>
                            <td>21”</td>
                            <td>28”</td>
                          </tr>
                          <tr>
                            <td>XL</td>
                            <td>22”</td>
                            <td>29”</td>
                          </tr>
                          <tr>
                            <td>2XL</td>
                            <td>23”</td>
                            <td>30”</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row mt-5 p-0">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="inputWrapper">
                        <label>T-shirt size *</label>
                        <Select
                          name="size"
                          defaultSelect="Select Size"
                          disable={
                            !selectedProducts.some(
                              (item) => item.name.toLowerCase().includes("shirt")
                            )
                          }
                          options={[
                            {
                              value: "2XS",
                              name: "2XS",
                            },
                            {
                              value: "XS",
                              name: "XS",
                            },
                            {
                              value: "S",
                              name: "S",
                            },
                            {
                              value: "M",
                              name: "M",
                            },
                            {
                              value: "L",
                              name: "L",
                            },
                            {
                              value: "XL",
                              name: "XL",
                            },
                            {
                              value: "2XL",
                              name: "2XL",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                      <div className="inputWrapper">
                        <label>Contact Number *</label>
                        <Input
                          name="contact"
                          placeholder="Type here"
                          wrapperClasses="Registration__form__input"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="inputWrapper">
                    <label>Delivery method *</label>
                    <Select
                      name="deliveryMethod"
                      defaultSelect="Select delivery method"
                      onChange={(e) => setDeliveryMethod(e.target.value)}
                      options={[
                        {
                          value: "Collect from school",
                          name: "Collect from school",
                        },
                        {
                          value: "Courier",
                          name: "Courier",
                        },
                      ]}
                    />
                    <label>
                      If you select 'Courier' as your delivery method, 500LKR
                      wiil be added to total amount.
                    </label>
                  </div>
                  <div className="inputWrapper">
                    <h3 className="mt-4">
                      Total amount:{" "}
                      {deliveryMethod === "Courier"
                        ? total + courierCharges
                        : total}
                      LKR
                    </h3>
                    <label>Payment Proof *</label>
                    <br />
                    <label>
                      (The relevant amount of your order should be deposited to
                      the following bank account and upload payment receipt.)
                    </label>
                    <p className="tx-center">
                      Bank: BOC, Godakawela branch
                      <br />
                      A/C no: 0077815215
                      <br />
                      A/C holder: Tharusha Gunawardana
                    </p>
                    <FileSelect
                      fileOnChange={(e) => setSlip(e.target.files[0])}
                    />
                  </div>
                  {deliveryMethod === "Courier" ? (
                    <>
                      <h5 className="mt-5">Shipping address</h5>
                      <div className="inputWrapper">
                        <label>Street address *</label>
                        <Input
                          name="streetAddress"
                          placeholder="Type your house number and street name"
                          wrapperClasses="Registration__form__input"
                          onChange={(val) =>
                            setDeliveryData({
                              ...deliveryData,
                              address: val,
                            })
                          }
                        />
                      </div>
                      <div className="row p-0">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="inputWrapper">
                            <label>District *</label>
                            <Select
                              name="district"
                              defaultSelect="Select your district"
                              options={districtData}
                              onChange={(e) => {
                                setCityData(
                                  cities[e.target.value].cities.map((item) => {
                                    return { value: item, name: item };
                                  })
                                );
                                setDeliveryData({
                                  ...deliveryData,
                                  district: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                          <div className="inputWrapper">
                            <label>City/Town *</label>
                            <Select
                              name="district"
                              defaultSelect="Select your city/town"
                              options={cityData}
                              onChange={(e) => {
                                setDeliveryData({
                                  ...deliveryData,
                                  city: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="inputWrapper mt-4">
                        <label>Postal code/ZIP *</label>
                        <Input
                          name="Postal"
                          placeholder="Type postal code/ZIP"
                          type="number"
                          wrapperClasses="Registration__form__input"
                          onChange={(val) =>
                            setDeliveryData({
                              ...deliveryData,
                              postalCode: val,
                            })
                          }
                        />
                      </div>
                    </>
                  ) : null}
                  <button
                    type="button"
                    onClick={() => {
                      setModelOpen(true);
                    }}
                    className="mt-4 w-100 submit"
                  >
                    Submit
                  </button>
                  {modelOpen ? (
                    <Model wrapperClassName="registrationPopup">
                      <h2>Are you sure ?</h2>
                      <p>
                        The information submitted in the form cannot be changed
                        again. So are you sure about the information you entered
                        ?
                      </p>
                      <div className="action__buttons__container w-100 d-flex">
                        <button
                          className="cancel"
                          onClick={() => {
                            setModelOpen(false);
                          }}
                        >
                          No, I need to check.
                        </button>
                        <button className="submit " type="submit">
                          Yes, I’m sure. Submit
                        </button>
                      </div>
                    </Model>
                  ) : null}
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default TShirts;
