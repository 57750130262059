import React, { useEffect, useState } from "react";
import "./Product.scss";
import Input from "../Input";
import Model from "../Model";
import Select from "../Select";
function Product({ product, onProductSelect }) {
  const [productModelOpen, setProductModelOpen] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [qtyCount, setQtyCount] = useState(1);

  useEffect(() => {
    if (product.product.toLowerCase().includes("wrist band")) {
      setQtyCount(4);
    } else {
      setQtyCount(2);
    }
  }, [product]);

  /**
   * Handles the mouseenter event on the product element.
   * The event is used to set the state with the mouse position and the
   * width and height of the product element.
   * @param {MouseEvent} e - The mouseenter event object.
   */
  const onMouseEnter = (e) => {
    const { clientX, clientY } = e;
    setX(clientX);
    setY(clientY);
    // Get the size of the product element
    const size = e.currentTarget.getBoundingClientRect();
    setWidth(size.width);
    setHeight(size.height);
  };

  /**
   * Handles the mousemove event on the product element.
   * The event is used to calculate the percentage of the width and height
   * that the mouse has moved relative to the center of the element.
   * This is used to set the CSS variables for the --x and --y values.
   * @param {MouseEvent} e - The mousemove event object.
   */
  const onMouseMove = (e) => {
    const element = e.target;
    const { clientX, clientY } = e;
    const horizontal =
      Math.floor(((clientX - x) / width) * 100) > 0
        ? Math.floor(((clientX - x) / width) * 100)
        : 0;
    const vertical =
      Math.floor(((clientY - y) / height) * 100) > 0
        ? Math.floor(((clientY - y) / height) * 100)
        : 0;
    element.style.setProperty("--x", `${horizontal}%`);
    element.style.setProperty("--y", `${vertical}%`);
  };

  const handleQtyChange = (e) => {
    const value = e.target.value ? parseInt(e.target.value) : 1;
    onProductSelect(product.product, product.price,value, product.type);
  };


  return (
    <>
      <div className="product">
        
        <img
          src={product.image}
          alt="tShirt"
          onClick={() => setProductModelOpen(true)}
        />
        <span className="product__name">
          <b>{product.product}</b>
          <br />
          {product.price}LKR
        </span>
        <Select
          name="QTY"
          wrapperClasses="Product__qty"
          options={Array.from(
            { length: qtyCount },
            (_, index) => index + 1
          ).map((item,index) => ({ value: index, name: index }))}
          onChange={handleQtyChange}
        />
      </div>
      {productModelOpen ? (
        <Model wrapperClassName="productModel">
          <i className="bx bx-x" onClick={() => setProductModelOpen(false)}></i>

          <div
            className="productImage"
            onMouseEnter={onMouseEnter}
            onMouseMove={onMouseMove}
          >
            <img src={product.image} alt="productImage" />
          </div>
        </Model>
      ) : null}
    </>
  );
}

export default Product;
